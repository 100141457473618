import React, {FC, useEffect, useState} from "react";
import useAuthGuard from "@modules/auth/useAuthGuard";
import {GuardProps} from "@modules/auth/guards";

// @todo check if something is off here in terms of logging out when otp is active
const InitialGuard: FC<GuardProps> = ({Page, children}) => {
    return <>
        {Page ? <Page/> : children}
    </>;
};

const ActiveGuard: FC<GuardProps> = ({Page, children}) => {
    useAuthGuard();
    return <>
        {Page ? <Page/> : children}
    </>;
};

const AuthGuard: FC<GuardProps> = (props) => {

    /**
     * Prevent double-checking on initial load
     */
    const [initialGuard, setInitialGuard] = useState<boolean | null>(null)
    useEffect(() => {
        if (initialGuard !== null) {
            return;
        }
        setInitialGuard(document.readyState === 'interactive')
    }, [document.readyState]);

    return <>
        {initialGuard === true && <InitialGuard {...props} />}
        {initialGuard === false && <ActiveGuard {...props} />}
    </>;
};

export default AuthGuard;